<template>
  <div style="height:100%" v-if="loading">
    <loader size="xxs" :loader-image="false" />
  </div>
  <div class="cardClass" v-else>
    <slot />
    <footer
      class="flex"
      :class="footerHasBorder ? 'cardFooter' : ''"
      v-if="hasFooterSlot"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    footerHasBorder: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style>
.cardClass {
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
}
.cardFooter {
  border-top: 1px dashed #878e99;
}
</style>
